import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Responsive from 'react-responsive'

//import Animations from './animation'

import './layout.css'
import '../css/typography.scss'
import '../css/custom.css'
import '../css/color.scss'

const Desktop = props => <Responsive {...props} minWidth={992} />
const Mobile = props => <Responsive {...props} maxWidth={991} />

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fileAbsolutePath: { regex: "/projets/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                date(formatString: "YYYY")
                path
                title
                category
                hierarchy
                idu
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="fr" />
        </Helmet>
        <Desktop>
          <div
            style={{
              margin: '0',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'flex-start',
            }}
          >
            {children}
          </div>
        </Desktop>
        <Mobile>
          <div
            id="outer-container"
            style={{
              margin: '0',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
            }}
          >
            {children}
          </div>
        </Mobile>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
