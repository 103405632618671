import React from 'react'

import './content.css'

const Content = ({ children, color }) => (
  <section className={[color, 'content'].join(' ')} id="page-wrap">
    {children}
  </section>
)

export default Content
